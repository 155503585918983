const magnificPopup = require('magnific-popup');
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const html = $('html');
const modal = $('.modal')[0];

function modalOpen(el, src, type) {
  let otherCards;
  // console.log('log el = ',el)
  // console.log('log type = ',type)
  $.magnificPopup.open({
    items: {
      src: src,
      type: type || 'inline'
    },
    mainClass: 'mfp-move-from-top',
    closeBtnInside: false,
    // fixedContentPos: false,
    fixedContentPos: true,
    //removalDelay: 500,
    callbacks: {
      beforeOpen: function() {
          // disableBodyScroll(modal);
      },
      afterClose: function() {
          // enableBodyScroll(modal);
      },
    },
    midClick: true
  });
}

window.modalOpen = function(src, type) {
    modalOpen(null, src, type);
};

$(document).on('click', '.js-modal', function(event) {
    const el = $(this);
    const src = $(this).data('mfp-src');
    const type = $(this).data('mfp-ajax') || 'inline';
    event.preventDefault();

    modalOpen(el, src, type);
});

$(document).on('click', '.js-modalClose', (event) => {
	event.preventDefault();
	$.magnificPopup.close();
});

$(document).on('submit', '.modal form', () => {
    setTimeout(() => {
        $.magnificPopup.close();
    }, 3000)
});

$(document).on('clearForms',  function(event) {
  let forms = document.querySelectorAll('.js-validate');
  forms.forEach(function(item,index){
    item.reset();
    $(item).find('.form-input, .form-textarea').removeClass('is-success').removeClass('is-error');
    $(item).find('.form-input, .form-textarea').find('.form-label').removeClass('active');
    $(item).find('.form-agree label').removeClass('is-error');
    $(item).find('.button').prop('disabled',false);
  });
});

$(document).on('sendSuccess',  function(event) {
  modalOpen(this, '#send-success', 'inline');
});

$(document).on('sendError',  function(event) {
  modalOpen(this, '#send-error', 'inline');
});
