import Swiper from 'swiper/swiper-bundle';

if ($('.js-sliderApartment').length > 0) {
	const sliderCounterCurrent = $('.js-sliderApartmentCounterCurrent');
    const sliderCounterFull = $('.js-sliderApartmentCounterFull');

	let sliderApartment = new Swiper('.js-sliderApartment .swiper-container', {
		speed: 300,
		navigation: {
			nextEl: '.js-sliderApartmentNext',
			prevEl: '.js-sliderApartmentPrev',
		},
	});

	sliderApartment.on('slideChange', function() {
    	sliderCounterCurrent.text(this.realIndex + 1);
    });
}