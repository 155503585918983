export function mapInit(){
  const mapContainer = $('#map');
  const mapMarkers = projectsMarkers;
  const mapCoords = mapContainer.data('center').split(',');
  const mapCenter = [mapCoords[0],mapCoords[1]];
  let allMarkers = [];
  let filteredMarkers = [];
  let currentFilterArray = [];
  let mapControlFilterLength = $('.js-mapControlFilter').length;

  const zoomInButton = $('.js-mapControlPlus');
  const zoomOutButton = $('.js-mapControlMinus');

  const map = new ymaps.Map(mapContainer[0], {
    center: mapCenter,
    zoom: 11,
    controls: [],
    }, {suppressMapOpenBlock: true});

  map.events.add('click', function () {
    map.balloon.close();
  });

  zoomInButton.on('click', function() {
    if(map.getZoom() > 21) return false;
    map.setZoom(map.getZoom() + 1);
  });

  zoomOutButton.on('click', function() {
    if(map.getZoom() < 5) return false;
    map.setZoom(map.getZoom() - 1);
  });

  $(document).on('change', '.js-mapControlFilter', function() {
    currentFilterArray = [];
    currentFilterArray.push('tverskoy');

    if ($('.js-mapControlFilter:checked').length > 0 && $('.js-mapControlFilter:checked').length < mapControlFilterLength) {
      $('.js-mapControlFilter:checked').each(function() {
        let _this = $(this);
        let filterVal = _this.data('filter');

        currentFilterArray.push(filterVal);
      })
    } else {
      $('.js-mapControlFilter').each(function() {
        let _this = $(this);
        let filterVal = _this.data('filter');

        currentFilterArray.push(filterVal);
      });
    }

    filterMarkers();
  });

  /*
   * Добавление меток на карту
   */

  function addMarkers(markers){
    $.each(markers, function(i){
      const location = markers[i].location;
      const pin = markers[i].pin;
      const pinSize = [26, 26];
      const pinOffset = [-13, -13];
      let marker;
      var baloonContent = generateBaloon(markers[i]);

      marker = new ymaps.Placemark(location, {
        // Содержимое  балуна
        balloonContentBody: baloonContent,
      }, {
        iconLayout: 'default#imageWithContent',
        iconImageHref: pin,
        iconImageSize: pinSize,
        iconImageOffset: pinOffset,
        // balloonOffset: [-13, -5],
      });

      allMarkers.push(marker);
      map.geoObjects.add(marker);
    });
  }

  /*
   * Фильтрация меток
   */

  function filterMarkers(type){
    filteredMarkers = [];

    $.each(mapMarkers, function(i){
      let thisElem = mapMarkers[i].type;

      // if(mapMarkers[i].type == type){
      //   filteredMarkers.push(mapMarkers[i]);
      // }

      if (filterItems(thisElem, currentFilterArray).length > 0) {
        filteredMarkers.push(mapMarkers[i]);
      }
    });

    deleteMarkers();
    addMarkers(filteredMarkers);

    setBounds();
  }


  // /*
  //  * Удаление всех меток с карты
  //  */

  function deleteMarkers(){
    $.each(allMarkers, function(i){
      map.geoObjects.remove(allMarkers[i]);
    });

    allMarkers = [];
  }

  function setBounds(){
    if(mapMarkers.length > 1){
      setTimeout(() => {
        map.setBounds(map.geoObjects.getBounds(), {checkZoomRange:true, preciseZoom: true, zoomMargin: 40});
      }, 400);
    } else if (mapMarkers.length == 1) {
      let mapOneMarkPos = mapMarkers[0].location;
      map.setCenter(mapOneMarkPos);
    }

    map.container.fitToViewport();
  }

  addMarkers(mapMarkers);
  setBounds();

  function generateBaloon(marker){
    const tpl = `
      <div class="pin-item">
        <h2 class="pin-item__title">${marker.title}</h2>
        <p class="pin-item__text">${marker.text}</p>
      </div>`;
    return tpl;
  }

  function filterItems(query, arrayCurrent) {
    return arrayCurrent.filter(function(el) {
        return el.toLowerCase().indexOf(query.toLowerCase()) > -1;
    })
  }
}
