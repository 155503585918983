const lang = $('html').attr('lang');
const ru = (lang === 'ru');
const errors = {
	ru: {
		email: 'Введите корректный email',
		phone: 'Указан неверный формат телефона',
		password: 'Пароль должен содержать от 8 символов, латинские символы, символы верхнего регистра',
	},
	en: {
		email: 'Enter the correct email address',
		phone: 'Invalid phone format specified',
		password: 'The password must contain at least 8 characters, latin characters, uppercase characters',
	}
}

if ( lang === 'ru') {
	$.extend($.validator.messages, {
		required: "Заполните поле",
		remote: "Введите правильное значение",
		email: "Введите корректную электронную почту",
		url: "Введите корректный URL",
		date: "Введите корректную дату",
		dateISO: "Введите корректную дату в формате ISO",
		number: "Введите число",
		digits: "Вводите только цифры",
		letters: "Вводите только буквы",
		creditcard: "Введите правильный номер кредитной карты",
		equalTo: "Пароли не совпадают",
		extension: "Выберите файл с правильным расширением",
		maxlength: $.validator.format( "Максимальная длина описания - {0} символов" ),
		minlength: $.validator.format( "Введите не меньше {0} символов" ),
		rangelength: $.validator.format( "Введите значение длиной от {0} до {1} символов" ),
		range: $.validator.format( "Введите число от {0} до {1}" ),
		max: $.validator.format( "Максимальная длина описания - {0} символов" ),
		min: $.validator.format( "Введите число, большее или равное {0}" ),
	});
} else {
	$.extend($.validator.messages, {
		required: "Fill in the field",
		remote: "Enter the correct value",
		email: "Enter the correct email address",
		url: "Enter the correct URL",
		date: "Enter the correct date",
		dateISO: "Enter the correct date in ISO format",
		number: "Enter a number",
		digits: "Enter only numbers",
		letters: "Enter only letters",
		creditcard: "Enter the correct credit card number",
		equalTo: "Passwords don't match",
		extension: "Select the file with the correct extension",
		maxlength: $.validator.format( "The maximum length of the description is {0} characters" ),
		minlength: $.validator.format( "Enter at least {0} characters" ),
		rangelength: $.validator.format( "Enter a value between {0} and {1} characters long" ),
		range: $.validator.format( "Enter a number from {0} to {1}" ),
		max: $.validator.format( "The maximum length of the description is {0} characters" ),
		min: $.validator.format( "Enter a number greater than or equal to {0}" ),
	});
}

function initValidate() {
	$('.js-validate').each((index, form) => {
	// $(form).find('[type="submit"]').prop('disabled', true);

		$(form).validate({
			ignore: [],
			errorElement: 'span',
			errorClass: 'label-error',
			rules : {
				'password_confirm' : {
					equalTo : '.js-password',
					minlength: 8
				},
				'reg_passConfirm' : {
					equalTo : '.js-confirm',
					minlength: 8
				},
			},
			highlight: function(element) {
				setTimeout(() => {
					if(!$(element).attr('disabled')){
						$(element).parent().removeClass('is-success');
						$(element).parent().addClass('is-error');

						if($(element).parent('.jq-checkbox')) {
							const checkboxParent = $(element).parents('label');
							checkboxParent.append(checkboxParent.find('.label-error'));
						}

						if($(element).attr('type') == 'checkbox' || $(element).hasClass('select') || $(element).attr('type') == 'radio'){
							const parent = $(element).parent();

							parent.append(parent.find('.label-error'));
						}
					}
				});
			},
			unhighlight: function(element) {
				setTimeout(() => {
					if(!$(element).attr('disabled')){
						if($(element).val() != '') $(element).parent().addClass('is-success');
						$(element).parent().removeClass('is-error');
					}
				});
			}
		});

		// if ( $(form).validate().checkForm() ) {
		// 	$(form).find('[type="submit"]').prop('disabled', false)
		// } else {
		// 	$(form).find('[type="submit"]').prop('disabled', true)
		// }
		//
		// $('input', form).on('change input blur', () => {
		// 	if ( $(form).validate().checkForm() ) {
		// 		$(form).find('[type="submit"]').prop('disabled', false)
		// 	} else {
		// 		$(form).find('[type="submit"]').prop('disabled', true)
		// 	}
		// })
	});
}

$(document).on('initValidateTrigger', () => {
	initValidate()
});

$(document).trigger('initValidateTrigger');

$(document).on('change input blur', '.form-input', (event) => {
	const _this = $(event.currentTarget);
	if(_this.val() == '') _this.parent().removeClass('is-success');
	(_this.val().length ) ? _this.addClass('is-filled') : _this.removeClass('is-filled');
});

$(document).on('change input blur', '.subscribe__input', (event) => {
	const _this = $(event.currentTarget);
	( _this.val().length ) ? _this.parent().addClass('is-filled') :  _this.parent().removeClass('is-filled');
});

$(document).on('change input blur', '.form-textarea textarea', (event) => {
	const _this = $(event.currentTarget);
	_this.valid();
});

$(document).on('change', '.jq-checkbox input', (event) => {
	const _this = $(event.currentTarget);
	_this.valid();
});

$.validator.addMethod("validpassword", function(value, element) {
	if(value.match(/[A-z]/) && !value.match(/[А-я]/) && value.match(/[A-Z]/)) return true;

}, (ru) ? errors.ru?.password : errors.en?.password);

$.validator.addClassRules('js-passwordValidate', {
	validpassword: true
});

$('.js-passwordValidate').on('focus', function() {
	if ($('.js-passwordValidate').val() == '') {
		$('.js-passwordValidate').parent().addClass('is-error');
	}
});

$.validator.addMethod('phonecustom', function(value, element) {
	if(value == '+7 (___) ___-__-__') return true;
	return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/);
}, (ru) ? errors.ru?.phone : errors.en?.phone);

$.validator.addClassRules('js-mask', {
	phonecustom: true
});

$.validator.addMethod('emailcustom', function (value, element) {
	return value.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9A-Яа-я_\-\.])+\.([A-Za-zA-Яа-я_]{2,4})$/);
}, (ru) ? errors.ru?.email : errors.en?.email);

$.validator.addClassRules('js-email', {
	emailcustom: true
});


$(document).ready(function() {
    $('.js-letters').bind("change keyup input click", function() {
     if (this.value.match(/[^а-яА-Яa-zA-Z\s]/g)) {
        this.value = this.value.replace(/[^а-яА-Яa-zA-Z\s]/g, '');
        }
    });

    $(document).on('submit', '.js-demo-send', function (event) {
      event.preventDefault();
      let $this = $(this);

      if ($(this).valid()) {
          var form = $this;
          console.log('valid -submit');
          // var formdata = false;
          // if (window.FormData) {
          //     formdata = new FormData(form[0]);
          // }
          var formData = new FormData(this);
          console.log('formData = ',formData);
          $.ajax({
              url: $(this).attr('action'),
              type: "POST",
              data: formData || form.serialize(),
              cache: false,
              contentType: false,
              processData: false,
              success: function (res) {
                  console.log('ajax_success');
                  $(document).trigger('sendSuccess');
                  setTimeout(() => {
                    $(document).trigger('clearForms');
                  }, 50);
              },
              error: function (error) {
                console.log('ajax_error');
                $(document).trigger('sendError');
              }
          });
      } else {
        console.log('NOT valid -submit');
      }
    });
});

