$(document).ready(function () {

  if($('.js-impulseLink').length){
    $('.js-impulseLink').click(function(){
      const impulseId = $(this).data('impulse-link') || 0;
      $('.map-impulse__item').removeClass('active');
      $(this).addClass('active');
      $('.map-impulse__map-point').removeClass('active');
      $('.map-impulse__map-point[data-impulse-point=' +impulseId+ ']').addClass('active');
      // $('#tabs .tabs-nav a[href=' + $(this).data('id')+ ']').click();
    });

  }
});
