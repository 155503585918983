$(document).on('click', '.js-openLevel:not(.is_active)',function() {
	const _this = $(this);
  console.log('_this = ',_this);
	const indexElem = _this.parent().index();
  console.log('indexElem = ',indexElem);

	$('.js-openLevel').not(_this).removeClass('is_active');
	_this.addClass('is_active');
	$('.js-levelTab').eq(indexElem).addClass('is_show').siblings().removeClass('is_show');
});

function levelTabOffset() {
  let windowWidth = $(window).innerWidth(),
    container = $('.section-level .container'),
    containerWidth = container.innerWidth(),
    containerPadding = (container.innerWidth() - container.width())/2,
    sumMinus = (windowWidth - containerWidth)/2 + containerPadding;
    sumMinus = sumMinus*(-1);

  return sumMinus;
};
$(document).ready(function () {
  if($('.js-levelNavSizer').length){

    $(window).on("resize", function () {
      const sizerElemnt = $('.js-levelNavSizer');
      sizerElemnt.removeAttr('style');
      const sum = levelTabOffset();
      const cols = sizerElemnt.find('.level-nav__row').find('.level-nav__elem-col');
      if($(window).innerWidth()<1024){
        sizerElemnt.css('margin-left',sum+'px');
        $(cols[0]).css('padding-left',sum*(-1)+'px');
        $(cols[cols.length - 1]).css('padding-right',sum*(-1)+'px');
        sizerElemnt.css('margin-right',sum+'px');
      } else {
        $(cols).removeAttr('style');
        sizerElemnt.removeAttr('style');
      }
    });
    // .js-levelNavSizer
    // levelTabOffset
    $(window).trigger('resize');
  }
});

