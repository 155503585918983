function homeOffset() {
  let windowWidth = $(window).innerWidth(),
    container = $('.home-scr .container'),
    containerWidth = container.innerWidth(),
    containerPadding = (container.innerWidth() - container.width())/2,
    sumMinus = (windowWidth - containerWidth)/2 + containerPadding;
    sumMinus = sumMinus*(-1);

  return sumMinus;
};
$(document).ready(function () {
  if($('.js-homeSizer').length){

    $(window).on("resize", function () {
      $('.js-homeSizer').removeAttr('style');
      const sum = homeOffset();
      $('.js-homeSizer').css('margin-right',sum+'px');
    });
    // .js-homeSizer
    // homeOffset
    $(window).trigger('resize');
  }

});
