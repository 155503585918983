import Swiper from 'swiper/swiper-bundle';

if ($('.js-sliderLocation').length > 0) {

	let sliderLocation = new Swiper('.js-sliderLocation', {
    // Default parameters
    slidesPerView: 'auto',
    spaceBetween: 32,
    observeParents: true,
    observeSlideChildren: true,
    observer: true,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        // slidesPerView: 2,
        spaceBetween: 16
      },
      // when window width is >= 480px
      480: {
        // slidesPerView: 3,
        spaceBetween: 16
      },
      // when window width is >= 640px
      992: {
        // slidesPerView: 4,
        spaceBetween: 32
      }
    },
		navigation: {
			nextEl: '.js-sliderLocationNext',
			prevEl: '.js-sliderLocationPrev',
		},
	});
}
