
$(document).ready(() => {
  if ($(".js--burger-mobile").length > 0) {
    $(".js--burger-mobile").click(function(e) {
      e.preventDefault();
      $(this).toggleClass("burger--open");
      $(".menu-mobile").toggleClass("menu-mobile--open");
      $(".menu-desktop").toggleClass("menu-desktop--open");
      $("body").toggleClass("body-menu-open");
      $("html").toggleClass("html-menu-open");
    });
  }
  if ($(".js-scrollToElem").length > 0) {
    $('.js-scrollToElem').click(function (e) {
      e.preventDefault();

      // ловим клик по ссылке с классом go_to
      var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .

      if ($(scroll_el).length != 0) {
        // проверим существование элемента чтобы избежать ошибки
        $('html, body').animate({
          scrollTop: $(scroll_el).offset().top
        }, 300); // анимируем скроолинг к элементу scroll_el
      }
      return false; // выключаем стандартное действие
    });
  }
  if ($(".js-menuScrollToElem").length > 0) {
    $('.js-menuScrollToElem').click(function () {
      // ловим клик по ссылке с классом go_to
      var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .

      if ($(scroll_el).length != 0) {
        // проверим существование элемента чтобы избежать ошибки
        $('.js--burger-mobile').trigger('click');
        console.log('1');
        setTimeout(() => {
          console.log('2');
          $('html, body').animate({
            scrollTop: $(scroll_el).offset().top
          }, 300); // анимируем скроолинг к элементу scroll_el
          console.log('3');
        }, 350);
      }
      return false; // выключаем стандартное действие
    });
  }
});
