import svg4everybody from 'svg4everybody'
import $ from 'jquery'

svg4everybody()

window.$ = $
window.jQuery = $

import './vendor/_jquery-validation'
import './vendor/_masked-input'
