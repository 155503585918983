import Swiper from 'swiper/swiper-bundle';

if ($('.js-sliderHistory').length > 0) {
	const sliderCounterCurrent = $('.js-sliderHistoryCounterCurrent');
    const sliderCounterFull = $('.js-sliderHistoryCounterFull');

	let sliderHistory = new Swiper('.js-sliderHistory .swiper-container', {
		speed: 300,
		navigation: {
			nextEl: '.js-sliderHistoryNext',
			prevEl: '.js-sliderHistoryPrev',
		},
	});

  sliderHistory.on('slideChange', function() {
    sliderCounterCurrent.text(this.realIndex + 1);
  });

}
